import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {
  BaseUser,
  DialerRole,
  SingleExecutiveRule,
  UserRole,
  UserType,
  User_Permission,
  userRoleToJSON,
  userTypeFromJSON,
  userTypeToJSON,
} from '../models';
import { getUserCollection } from '../misc/getUserCollection';
import { TierLevel, TierLevelContainer } from '../models/classes/executive';
import { Features } from '../models/classes/misc';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private authService: AuthService,
    private functions: Functions
  ) {}

  setPermission = (
    grantedUser: BaseUser,
    role: UserRole | UserRole[],
    dialerRole?: DialerRole,
    isUpdate = false,
    singleExecutiveRules?: SingleExecutiveRule[],
    sendInvitationMail = true,
    isManagingUser = false,
    tierLevels?: TierLevel[],
    grantedUserCollection?: string,
    options?: {
      password?: string;
      features?: Features;
    }
  ) =>
    new Promise((resolve, reject) => {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(user => {
          if (!user) {
            console.error('not authenticated');
            return;
          }

          const innerUserCollection = getUserCollection(user.userType);

          if (role instanceof Array) {
            if (role.length > 0) {
              grantedUser.userRole = role[0];
            }
          } else {
            grantedUser.userRole = role;
          }

          if (grantedUser.userRole === UserRole.admin) {
            grantedUser.canImpersonate = true;
          }

          if (dialerRole) {
            grantedUser.dialerRole = dialerRole;
          }

          if (!isUpdate && !grantedUser.userType) {
            console.error('userType is undefined');
            grantedUser.userType = UserType.AGENT;
            // return;
          }

          const insertBody: { [key: string]: unknown } = {
            userCollection: innerUserCollection,
            userId: user.id,
            grantedUserId: grantedUser.id,
            grantedUser: BaseUser.toJSON(grantedUser),
            grantedUserCollection,
            singleExecutiveRules,
            sendInvitationMail,
            isUpdate,
            isManagingUser,
            tierLevelContainer:
              tierLevels && tierLevels?.length > 0
                ? TierLevelContainer.toJSON({ tierLevels })
                : null,
          };

          if (options?.password) {
            insertBody['password'] = options.password;
          }

          if (options?.features) {
            insertBody['features'] = Features.toJSON(options.features);
          }

          if (singleExecutiveRules) {
            insertBody['singleExecutiveRules'] = singleExecutiveRules.map(s =>
              SingleExecutiveRule.toJSON(s)
            );
          }

          if (role instanceof Array) {
            insertBody['roles'] = role.reduce(
              (acc, role) => {
                acc[userRoleToJSON(role)] = true;
                return acc;
              },
              {} as { [key: string]: boolean }
            );
          } else {
            insertBody['role'] = userRoleToJSON(role);
          }

          console.log('insertBody', insertBody);

          httpsCallable(this.functions, 'permissionSet')(insertBody)
            .then(resolve)
            .catch(reject);
        });
    });

  setPermissionByTerm = (
    term: string,
    role: string | string[],
    options: { grantChildAccess?: boolean; canImpersonate?: boolean } = {}
  ): Promise<{ data: { ok: boolean } }> =>
    new Promise((resolve, reject) => {
      const { grantChildAccess, canImpersonate } = options;

      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(user => {
          if (!user) {
            console.error('not authenticated');
            return;
          }
          const userCollection = getUserCollection(user.userType);

          const insertBody: { [key: string]: unknown } = {
            userCollection,
            userType: userTypeFromJSON(user.userType),
            userId: user.id,
            term,
            canImpersonate,
            grantChildAccess,
          };

          if (role instanceof Array) {
            insertBody['roles'] = role.reduce(
              (acc, role) => {
                acc[role] = true;
                return acc;
              },
              {} as { [key: string]: boolean }
            );
          } else {
            insertBody['role'] = role;
          }

          httpsCallable(
            this.functions,
            'permissionSet'
          )(insertBody)
            .then(res => resolve(res as { data: { ok: boolean } }))
            .catch(reject);
        });
    });

  acceptInvitation = (
    permission: User_Permission | User_Permission[],
    grantedUserId?: string
  ) =>
    new Promise((resolve, reject) => {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(() => {
          const permissions =
            permission instanceof Array ? permission : [permission];

          if (permissions.length === 0) {
            console.error('permissions is empty');
            return;
          }

          const acceptBodies = [];
          for (const permission of permissions) {
            if (!permission.user) {
              console.error('permission.user is undefined');
              return;
            }

            if (!permission.user.userType) {
              console.error('permission.user.userType is undefined');
              return;
            }

            const acceptBody: {
              [key: string]: unknown;
            } = {
              granterUserId: permission.user?.id,
              granterUserType: userTypeToJSON(permission.user.userType),
              grantedUserId,
            };

            if (permission.grantedUserId) {
              acceptBody['grantedUserId'] = permission.grantedUserId;
            }
            if (permission.grantedUserType) {
              acceptBody['grantedUserType'] = permission.grantedUserType;
            }

            if (permission.grantChildAccess) {
              acceptBody['grantChildAccess'] = permission.grantChildAccess;
            }
            acceptBodies.push(acceptBody);
          }

          httpsCallable(this.functions, 'permissionAccept')(acceptBodies)
            .then(resolve)
            .catch(reject);
        });
    });

  claimPermission = (permission: User_Permission | User_Permission[]) =>
    new Promise((resolve, reject) => {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(user => {
          if (!user) {
            console.error('not authenticated');
            return;
          }

          const permissions =
            permission instanceof Array ? permission : [permission];

          if (permissions.length === 0) {
            console.error('permissions is empty');
            return;
          }

          const acceptBodies = [];
          for (const permission of permissions) {
            if (!permission.user) {
              console.error('permission.user is undefined');
              return;
            }

            if (!permission.user.userType) {
              console.error('permission.user.userType is undefined');
              return;
            }

            const acceptBody: {
              [key: string]: unknown;
            } = {
              granterUserId: permission.user?.id,
              granterUserType: userTypeToJSON(permission.user.userType),
              grantedUserId: permission.grantedUserId,
              grantedUserType: permission.grantedUserType,
            };

            if (permission.grantChildAccess) {
              acceptBody['grantChildAccess'] = permission.grantChildAccess;
            }
            acceptBodies.push(acceptBody);
          }

          httpsCallable(this.functions, 'permissionClaim')(acceptBodies)
            .then(resolve)
            .catch(reject);
        });
    });

  deletePermission = (grantedUserId: string, grantedUserCollection?: string) =>
    new Promise((resolve, reject) => {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(user => {
          if (!user) {
            console.error('not authenticated');
            return;
          }
          const userCollection = getUserCollection(user.userType);

          httpsCallable(
            this.functions,
            'permissionDelete'
          )({
            userCollection,
            userId: user.id,
            grantedUserId: grantedUserId,
            grantedUserCollection: grantedUserCollection,
          })
            .then(resolve)
            .catch(reject);
        });
    });
}
